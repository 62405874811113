.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    margin-top: 15px;
}

.grid-container-span-2{
    grid-column: span 2;
    text-align: center;
}

.grid-item {
    text-align: center;
}

.tournament-bracket-round-name{
    font-weight: bold;
}

.tournament-bracket-round-date{
    margin-bottom: 15px;
}
 