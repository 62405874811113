.tournament-bracket-cell{
    min-height: 30px;
}

.tournament-bracket-cell-border-top{
    border-top: 1px solid #000000;
}

.tournament-bracket-cell-border-right{
    border-right: 1px solid #000000;
}

.tournament-bracket-cell-border-bottom{
    border-bottom: 1px solid #000000;
}

.tournament-bracket-cell-border-left{
    border-left: 1px solid #000000;
}
