body {
  background-color: rgb(243, 243, 243) !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
    margin-bottom: 15px;
    border-bottom: 1px solid #C5C5C5;
    padding-bottom: 10px;
}

.text-left{
    text-align: left;
}

.text-center{
    text-align: center;
}

.text-right{
  text-align: right;
}

.loading-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 1.2em;
}

span.loading-character {

    font-size: 16px;
    line-height: 16px;
    background-color: #FFFFFF;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin-left: 5px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.font-weight-bold{
    font-weight: bold;
}

.map-location-name{
    font-weight: bold;
    font-size: 1.1em;
}

tr.under-pace, .under-pace td {
    background-color: #f8d7da !important;
    color: #FFFFFFFF !important;
}

.under-pace td a {
    color: #FFFFFF !important;
}

span.on-bubble, span.under-pace{
    display: inline-block;
    height: 13px;
    width: 13px;
    margin-right: 5px;
}

span.on-bubble{
    background-color: #fff3cd;

}

span.under-pace{
    background-color: #f8d7da;
}

.on-bubble-tooltip{
    margin-left: 3px;
    margin-right: 10px;
}

.pointer {
    cursor: pointer;
}

.login-loading, .submit-picks-loading{
    margin-left: 10px;
    position: relative;
    top: 8px;
}

.my-picks-map-container{
    height: 300px;
    width: 100%;
}

.locked-pick-trash{
    opacity: .5;
    cursor: default;
}

.green-icon{
    color: rgb(0, 163, 27)
}

.red-icon{
    color: rgb(175, 5, 5)
}

td.sorted-column{
    background-color: rgb(245, 231, 109);
}

.sort-icon{
    margin-left: 5px;
}

.hidden-sort-icon{
    visibility: hidden;
}

.sorting-icon{
    visibility: visible;
}

.sorting-icon.ascending{
    position: relative;
    top: 5px;
}

td.pl-3, th.pl-3{
    padding-left: 1rem !important;
}

.mr-3{
    margin-right: 1rem;
}

.header-text {
    display: inline-block;
    width: 50%;
}

td a.user-profile-link, table td a.table-link, a.user-profile-link{
    color: #000000;
    text-decoration: none;
}

a.user-profile-link:hover{
    text-decoration: underline;
}

.award-gold {
    color: #D4AF37;
}
.award-silver{
    color: #C0C0C0;
}
.award-bronze{
    color: #cd7f32;
}

.float-left{
    float: left;
}

.float-right{
    float: right;
}

.form-link{
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 0.5;
    color: #212529 !important;
    background-color: #e9ecef;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 33px;
}

.nws-link{
    color: #212529 !important;
    margin-right: 5px;
    text-decoration: none;
}

a.table-link{
    color: #000000;
}

a.table-link:hover{
    text-decoration: underline;
}

.card-body-loading{
    position: absolute;
    left: 48%;
    top: 48%;
}

.main-nav-dropdown > a, .main-nav-dropdown > a:hover {
    color: #FFFFFF;
}

.all-times-text{
    position: absolute;
    top: 45px;
    right: 10px;
    font-weight: bold;
    font-size: 14px;
}

.award-gold{
    color: #D4AF37;
}

.award-silver{
    color: #C0C0C0;
}

.award-bronze{
    color: #cd7f32;
}

.current-wx-icon{
    margin-left: 5px;
}

.current-weater-row div{
    margin: 0;
    padding: 0;
}

.navLink{
    margin-right: 15px;
}

.tournament-round-container{
    margin-top: 15px;
}

.tournament-round-container h5{
    font-size: 16px;
}

.flex-container {
    display: flex;
}
  
.flex-container > div {
    text-align: center;
}

.tournament-round-header{
    font-weight: bold;
}

.tournament-matchup-header{
    margin-bottom: 10px;
}

.tournament-matchup{
    border-bottom: 1px solid #C5C5C5;
    margin-bottom: 30px;
}

.tournamet-bracket{
    min-width: 1100px;
}

.tournament-player-name{
    display: block;
}

.tournament-matchup-summary{
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
}

.tournament-day-pick{
    border-left: 1px solid #c5c5c5;
    display: block;
    height: 100%;
}

.tournament-day-score{
    font-weight: bold;
    display: block;
}

.tournament-player-name-container{
    padding: 0px 10px;
    width: 20%;
}

.tournament-day-container{
    width: 16%;
}

.tournament-away-matchup{
    margin-bottom: 10px;
}

.tournament-card-body-container{
    padding-bottom: 15px;
    overflow-x: scroll;
}

.tournament-matchup:last-child{
    border: 0px;
    margin-bottom: 0px;
}

.tournament-matchup:last-child .tournament-matchup-summary{
    padding-bottom: 0px;
}

.nav-tabs .nav-link{
    color: #000000;
}

#adminNav.nav-link {
    max-height: 20px;
}